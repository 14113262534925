

































































































































































import { Component, Prop } from 'vue-property-decorator';
import { BIcon, BLink } from 'bootstrap-vue';
import { Getter, namespace, State } from 'vuex-class';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import FlagComponent from '@/components/FlagComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import ProductImage from '@/models/graphql/ProductImage';
import Exhibitor from '@/models/graphql/Exhibitor';
import CommunityUser from '@/models/graphql/CommunityUser';
import FileResource from '@/models/graphql/FileResource';
import { CommunityUserBookmarkFilter } from '@/graphql/_Filters/CommunityUserBookmarkFilter';
import CommunityUserBookmark from '@/models/graphql/CommunityUserBookmark';
import PillWidget from '@/components/pill/PillWidget.vue';
import { deepGet } from '@/utils/ObjectHelpers';
import Community from '@/models/graphql/Community';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ExtraProperty from '@/models/graphql/ExtraProperty';
import Booth from '@/models/graphql/Booth';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import ColorListComponent from '@/components/ColorListComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import ExtraPropertiesTypes from '@/utils/enums/ExtraPropertiesTypes';
import EntityType from '@/utils/enums/EntityType';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import { mixins } from 'vue-class-component';
import ProductCertificatePartial from '@/utils/partials/ProductCertificatePartial';
import FormatHelper from '@/utils/helpers/FormatHelper';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import BaseCardWidget from '@/components/cards/BaseCardWidget.vue';

const communityUserBookmarkStore = namespace('CommunityUserBookmarkStore');

@Component({
  components: {
    ColorListComponent,
    AvatarSoloWidget,
    FontAwesomeComponent,
    PillWidget,
    ButtonIconComponent,
    BIcon,
    BLink,
    FlagComponent,
  },
  inheritAttrs: false,
})
/* eslint-disable no-underscore-dangle */
export default class BoatProductCardWidget extends mixins(BreakpointWrapper, ProductCertificatePartial, BaseCardWidget) {
  @Getter
  authUser!: CommunityUser;

  @Getter
  community!: Community;

  @Prop({ required: true, default: '' })
  readonly uid!: string;

  @Prop({ required: true, default: 0 })
  readonly id!: number;

  @Prop({ required: true, default: '' })
  readonly name!: string;

  @Prop({ required: false, default: -1 })
  readonly price!: number;

  @Prop({ required: false, default: null })
  readonly exhibitor!: Exhibitor;

  @Prop({ required: true, default: '' })
  readonly images!: ProductImage[];

  @Prop({ default: false })
  readonly featured!: boolean;

  @Prop({ default: null })
  readonly cardRoute!: string;

  @Prop({ required: false, default: null })
  readonly _isBookmarked!: string | null;

  @Prop({ default: false })
  readonly _isRecommendedForMe!: boolean;

  @Prop({ default: false })
  readonly _isExhibitorFeatured!: boolean;

  @Prop({ default: 'DependencyWidgetStore' })
  readonly context!: string;

  @Prop({ required: false, default: true })
  readonly displayRecommendedBadge!: boolean;

  @Prop({ required: false, default: true })
  readonly displayFeaturedBorder!: boolean;

  @Prop({ required: false, default: () => [] })
  readonly extraProperties!: ExtraProperty[];

  @Prop({ required: false, default: () => [] })
  readonly displayExtraProperties!: [];

  @Prop({ required: false, default: () => [] })
  readonly booths!: Booth[];

  @communityUserBookmarkStore.Action
  bookmark!: (payload: CommunityUserBookmarkFilter) => Promise<CommunityUserBookmark | undefined>;

  @communityUserBookmarkStore.Action
  unBookmark!: (payload: CommunityUserBookmarkFilter) => Promise<CommunityUserBookmark | undefined>;

  @Getter
  protected featureByKey!: (key: FeatureKeys) => CommunityFeature;

  protected ExtraPropertiesType = ExtraPropertiesTypes;

  @State
  private dateLocale!: Locale;

  private empty = this.image === null;

  private bookmarked = this._isBookmarked;

  private FileResourceHelper = FileResourceHelper;

  get extraProps(): ExtraProperty[] {
    return this.extraProperties;
  }

  private get booth(): string {
    if (this.booths && this.booths.length > 0 && this.booths[0].number) {
      return `${this.$tc('boat-product-card.booth', 0, { booth: this.booths[0].number })}`;
    }
    return '';
  }

  private get properties(): { name: string; key: string; value: string | string[]; type: string }[] {
    return this.extraProperties
      .filter((ext) => ext.config && !['NMMA_CERTIFIED', 'CERTIFICATE'].includes(ext.config.key))
      // eslint-disable-next-line arrow-body-style
      .sort((a, b) => {
        if (!this.displayExtraProperties) {
          return 0;
        }
        return this.displayExtraProperties.findIndex(
          (el: { id: string; key: string }) => el.key === a.key,
        ) - this.displayExtraProperties.findIndex(
          (el: { id: string; key: string }) => el.key === b.key,
        );
      }).map((ext) => ({
        name: ext.config.name,
        value: (ExtraPropertiesTypes.COLOR === ext.config.valueType
          || ExtraPropertiesTypes.COLOR_LIST === ext.config.valueType)
          ? ext.value.split(/(?:\s*,\s*(?=#)|\s*,\s*(?=r)|\s*,\s*(?=h))+/)
          : ext.value,
        key: ext.config.key,
        type: ext.config.valueType,
      }));
  }

  private get handleBaseUrl(): string | null {
    if (this.cardRoute) {
      let r = this.cardRoute;
      const matches = this.cardRoute.match(/(%[a-zA-Z-_.[0-9\]*]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '').trim();
          const newValue = deepGet(this.$props, prop);
          if (newValue) {
            r = r.replaceAll(m, newValue);
          }
          if (this.$route.params[prop]) {
            r = r.replaceAll(m, this.$route.params[prop]);
          }
        });
        return r;
      }
      if (r[r.length - 1] === '/') {
        return `${r}${this.uid}`;
      }
      return `${r}/${this.uid}`;
    }
    return null;
  }

  private get image(): FileResource | null {
    return this.images
    && this.images.length > 0
    && 'fullFileResource' in this.images[0]
    && this.images[0].fullFileResource
      ? this.images[0].fullFileResource
      : null;
  }

  private get isExhibitorFeatured(): boolean {
    return this._isExhibitorFeatured
        && this.featureByKey(FeatureKeys.COMMUNITY_PRODUCTS_FEATURED_FEATURE)
        && this.featureByKey(FeatureKeys.COMMUNITY_PRODUCTS_FEATURED_FEATURE).enabled;
  }

  private toggleBookmark(): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }
    if (this.bookmarked) {
      const temp = this.bookmarked;
      this.bookmarked = null;
      this.unBookmark({ uid: temp })
        .catch(() => {
          this.bookmarked = temp;
        });
    } else {
      this.bookmarked = 'bookmarked';
      this.bookmark({
        userId: this.authUser.uid,
        linkedUserId: this.uid,
        entityType: EntityType.LARGE_PRODUCT,
      }).then((response) => {
        this.bookmarked = response?.uid || '';
        if (this.bookmarked) {
          this.$logger.logMatomoStats(
            this.authUser,
            this.community.code as string,
            EntityType.LARGE_PRODUCT,
            StatLoggerActions.ADD,
            '',
            this.id,
            this.uid,
            StatLoggerCategories.BOOKMARK,
            this.$i18n.locale,
          );
        }
      }).catch(() => {
        this.bookmarked = null;
      });
    }
  }

  private priceTag(): string {
    return `${FormatHelper.formatCurrency(this.price)}`;
  }
}
